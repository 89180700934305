<template>
  <div>
    <a-card title="出库报表">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker @change="changeRangePicker" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.search" placeholder="产品编号, 名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.batch_number" placeholder="批次编号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.delivery_order_number" placeholder="单据编号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button icon="download" @click="exportData" style="width: 100%">导出</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ y: 480 }"
            @change="changeTable"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { stockOutReportExport, stockOutReportList } from "@/api/report";
import { exportExcel } from "@/utils/excel";
import moment from "moment";

export default {
  data() {
    return {
      searchForm: { search: "", page: 1, page_size: 48, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "出库通知单号",
          dataIndex: "delivery_order_number",
          customRender: (_value, item) => item.delivery_order_item.number,
        },
        {
          title: "产品编号",
          dataIndex: "material__number",
          sorter: true,
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: "英文名称",
          dataIndex: "english_name",
          customRender: (_value, item) => item.material_item.english_name,
        },
        {
          title: "批号",
          dataIndex: "batch_number",
          customRender: (_value, item) => item.batch_item.number,
        },
        {
          title: "出库数量",
          dataIndex: "total_quantity",
          sorter: true,
        },
        {
          title: "单位",
          dataIndex: "unit",
          customRender: (_value, item) => item.material_item.unit,
        },
        {
          title: "产品单价",
          dataIndex: "material__unit_price",
          sorter: true,
          customRender: (_value, item) => item.material_item.unit_price,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          customRender: (_value, item) => item.delivery_order_item.create_time,
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      stockOutReportList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    changeRangePicker(date) {
      let startDate, endDate;
      if (date.length === 2) {
        startDate = date[0].format("YYYY-MM-DD");
        endDate = date[0].format("YYYY-MM-DD");
        endDate = moment(endDate).add(1, "days").format("YYYY-MM-DD");
      }

      this.searchForm.start_date = startDate;
      this.searchForm.end_date = endDate;
      this.search();
    },
    exportData() {
      stockOutReportExport(this.searchForm).then((resp) => {
        exportExcel(resp, "出库报表");
      });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
